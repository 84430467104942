import React, { useEffect } from 'react';
import './FormStep.scss';

import Form, { Field } from 'components/Form/Form';

import TermsAndConditionsPDF from 'assets/docs/terms-and-conditions.pdf';

const FormStep = props => {
  const {
    setCurrentStep,
    updateData,
    history,
  } = props;

  // update the current step
  useEffect(() => {
    setCurrentStep({
      name: 'Verify Your Account',
      index: 1,
    });
  }, [setCurrentStep]);

  /**
   * handleFormSubmit
   *
   * Handles the form submit.
   *
   * @type function
   * @since 0.0.1
   *
   * @param object data
   * @return NA
   */
  const handleFormSubmit = data => {
    updateData(data);
    history.push('/choose-your-bank');
  }

  // render
  return (
    <div className="step step-form">
      <header className="step__header">
        <h2 className="step-title">
          Verify Your Account
        </h2>
        <div className="step-description">
          <p>In order to expedite your mortgage application process, you can authorize your financial institution to securely transmit your bank account statements to us directly.</p>
          <p>Please fill in the information about yourself below.</p>
        </div>
      </header>

      <div className="step__content">
        <Form
          uniqueId="stepOneForm"
          onSubmit={handleFormSubmit}>
          <div className="row">
            <div className="col">
              <Field
                type="name"
                name="firstName"
                label="First Name"
                placeholder="Insert your first name"
                required
              />
            </div>

            <div className="col">
              <Field
                type="name"
                name="lastName"
                label="Last Name"
                placeholder="Insert your last name"
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Field
                type="date"
                name="dateOfBirth"
                label="Date Of Birth"
                placeholder="DD-MM-YYYY"
                required
              />
            </div>

            <div className="col">
              <Field
                type="loanNumber"
                name="loanNumber"
                label="Community Trust Loan Number"
                placeholder="123456"
                tooltip={(
                  <>
                    <p>Loan number can be found at the top left corner on the first page of the Community Trust Committment Letter.</p>
                    <p>If you do not have a number, please contact your mortgage broker, or enter 99999.</p>
                  </>
                )}
                required
              />
            </div>
          </div>

          <div className="form__submit">
            <button
              type="submit"
              title="Submit account information"
              aria-label="Submit account information"
              className="btn">
              <span>Verify Me</span>
            </button>

            <small className="terms-and-conditions">
              By clicking this button, you agree to the <a href={TermsAndConditionsPDF} rel="noopener noreferrer" target="_blank">Terms & Conditions</a>.
            </small>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default FormStep;
